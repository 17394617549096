.container-detail {
  padding: 15px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 0 auto;
}
.detail-content {
  width: 100%;
}
.detail-content .title {
  font-size: 20px;
  margin: 15px 0;
}
.pad-video-player {
  width: 100%;
  max-height: calc(100vh - 80px);
}
.video-player-wrapper {
  width: 100%;
  position: relative;
  padding-top: 56.25%;
}
@media (min-width: 1280px) {
  .detail-content {
    width: calc(100% - 10vw);
  }
}
@media (min-width: 1800px) {
  .detail-content {
    width: calc(100% - 20vw);
  }
}
.video-player-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 80px);
  border: 1px solid #eee;
  box-shadow: 0 15px 20px -16px rgba(0, 0, 0, 0.1);
}
.pad-next-play {
  width: 100%;
  margin-top: 20px;
}
.pad-detail-list {
  margin-top: 30px;
  overflow-x: scroll;
  white-space: nowrap;
}
.pad-detail-list a {
  text-decoration: none;
}
.pad-detail-list .video-item {
  display: inline-block;
  width: 200px;
  margin: 0 8px 20px;
}
.pad-detail-list .video-item > a {
  display: flex;
  flex-direction: column;
}
.pad-detail-list .video-item .cover {
  position: relative;
}
.pad-detail-list .video-item .cover img {
  width: 100%;
  display: block;
  border-radius: 10px;
  border: 1px solid #bebebf;
  box-shadow: 0 10px 15px -16px rgba(0, 0, 0, 0.1);
}
.pad-detail-list .video-item .cover .duration {
  position: absolute;
  left: 5px;
  bottom: 5px;
  padding: 3px;
  background-color: rgba(26, 46, 59, 0.85);
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.6);
}
.pad-detail-list .video-item .title {
  color: #28292b;
  text-decoration: none;
  margin-top: 10px;
  font-size: 15px;
  white-space: normal;
}
