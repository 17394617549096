.video-list-page {
  min-height: calc(100vh - 100px);
  position: relative;
  padding-bottom: 50px;
}
.banner {
  width: 100%;
  display: block;
}
.video-list-wrapper {
  padding: 15px;
}
.video-grid-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.video-grid-list a {
  text-decoration: none;
}
.video-grid-list .video-item {
  margin: 0 8px 20px;
}
.video-grid-list .video-item > a {
  display: flex;
  flex-direction: column;
}
.video-grid-list .video-item .cover {
  position: relative;
}
.video-grid-list .video-item .cover img {
  width: 100%;
  display: block;
  border-radius: 10px;
  border: 1px solid #bebebf;
  box-shadow: 0 10px 15px -16px rgba(0, 0, 0, 0.1);
}
.video-grid-list .video-item .cover .duration {
  position: absolute;
  left: 5px;
  bottom: 5px;
  padding: 3px;
  background-color: rgba(26, 46, 59, 0.85);
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.6);
}
.video-grid-list .video-item .title {
  color: #28292b;
  text-decoration: none;
  margin-top: 10px;
  font-size: 15px;
}
@media (min-width: 588px) {
  .video-grid-list .video-item {
    width: calc(50% - (8px*2));
  }
}
@media (min-width: 874px) {
  .video-grid-list .video-item {
    width: calc(33.3% - (8px*2));
  }
}
@media (min-width: 1160px) {
  .video-grid-list .video-item {
    width: calc(25% - (8px*2));
  }
}
@media (min-width: 1800px) {
  .video-grid-list .video-item {
    width: calc(20% - (8px*2));
  }
}
@media (max-width: 587px) {
  .video-grid-list .video-item {
    margin: 0 0 20px;
    width: 100%;
  }
}
.pad-footer {
  background-color: #ffa507;
  color: white;
  font-size: 15px;
  text-align: center;
  padding: 15px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
