.nav-container {
  display: flex;
  height: 50px;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #EBEBEB;
  padding-left: 15px;
}
.nav-container .logo {
  margin: 0 10px 0;
  height: 30px;
}
.nav-container .logo img {
  height: 30px;
}
.nav-container .pad-links {
  display: flex;
  align-items: center;
  height: 100%;
}
.nav-container .tab {
  text-decoration: none;
  margin: 0 5px;
  color: #28292b;
  border-bottom: 2px solid transparent;
  height: 100%;
  display: flex;
  align-items: center;
}
.nav-container .tab:hover {
  color: #f5aa38;
  border-bottom: 2px solid #f5aa38;
}
.nav-container .tab.selected {
  color: #f5aa38;
  border-bottom: 2px solid #f5aa38;
}
.nav-container .right {
  display: flex;
  padding: 0 15px;
}
.nav-container .right .name {
  flex: 1;
}
@media (max-width: 414px) {
  .nav-container .right .name {
    display: none;
  }
}
.nav-container .signout {
  padding: 0 15px;
  display: block;
  cursor: pointer;
}
