h2 {
  font-weight: normal;
}
.login-wrapper {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -60px;
}
.login-title {
  color: #333;
  font-size: 30px;
}
.login-form {
  margin-top: 40px;
}
.login-form .login-item {
  position: relative;
}
.login-form .login-item-title {
  position: absolute;
  bottom: 10px;
  left: 2px;
  transition: all 0.28s ease;
}
.login-form .login-item i {
  width: 100%;
}
.login-form .login-item i:after {
  content: "";
  position: absolute;
  z-index: 2;
  width: 0;
  height: 2px;
  background-color: #555;
  bottom: -1px;
  left: 50%;
  transition: all 0.25s ease;
}
.login-form .login-item + .login-item {
  margin-top: 30px;
}
.login-form .login-submit {
  display: block;
  margin: 20px auto;
  background-color: #F5AA38;
  box-shadow: 0 4px 9.6px 0.4px rgba(251, 170, 56, 0.5);
  border: none;
  width: 188px;
  height: 56px;
  border-color: #F5AA38;
  border-radius: 28px;
  color: #fff;
  outline: transparent;
  cursor: pointer;
}
.login-form .login-submit:hover {
  background-color: #F5AA38;
}
.login-form .login-submit:active {
  border: none;
  background-color: #F5AA38;
}
.login-form .login-submit[disabled] {
  background-color: #ccc;
  box-shadow: none;
}
.inputTitlePosition {
  transform: translateY(-20px);
  color: #F5AA38;
  font-size: 14px;
}
.login-item-input {
  border: none;
  outline: transparent;
  background-color: transparent;
  color: #333;
  border-bottom: 1px solid #ccc;
  width: 340px;
  max-width: 70vw;
  height: 40px;
  box-sizing: border-box;
  font-size: 16px;
}
.login-item-input + .login-item-title.animatedPosition {
  transform: translateY(-20px);
  color: #F5AA38;
  font-size: 14px;
}
.login-item-input.titleAnimation:focus + .login-item-title {
  transform: translateY(-20px);
  color: #F5AA38;
  font-size: 14px;
}
.login-item-input.titleAnimation:focus ~ i:after {
  width: 100%;
  left: 0;
}
.error-message {
  font-size: 14px;
  color: #e25443;
  text-align: center;
  min-height: 22px;
  line-height: 1.6;
  max-width: 70vw;
}
