h2 {
  font-weight: normal;
}

@whiteColor: #fff;
@gayColor: #333;
@gayDarkColor: #555;
@gayLightColor: #ccc;
@greenColor: #F5AA38;
@greenLightColor: #F5AA38;
@greenDarkColor: #F5AA38;
@greenBorderColor: #F5AA38;

.login-wrapper{
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -60px;
}

.login-title{
  color: @gayColor;
  font-size: 30px;
}

.login-form{
  margin-top: 40px;

  .login-item{
    position: relative;

    &-title {
      position: absolute;
      bottom: 10px;
      left: 2px;
      transition: all .28s ease;
    }

    i{
      width: 100%;
    }
    i:after{
      content: "";
      position: absolute;
      z-index: 2;
      width: 0;
      height: 2px;
      background-color: @gayDarkColor;
      bottom: -1px;
      left: 50%;
      transition: all .25s ease;
    }

  }

  .login-item+.login-item{
      margin-top: 30px;
  }

  .login-submit{
    display: block;
    margin: 20px auto;
    background-color: @greenColor;
    box-shadow: 0 4px 9.6px 0.4px rgba(251, 170, 56, 0.5);
    border: none;
    width: 188px;
    height: 56px;
    border-color: @greenBorderColor;
    border-radius: 28px;
    color: @whiteColor;
    outline: transparent;
    cursor: pointer;

    &:hover{
      background-color: @greenLightColor;
    }
    &:active{
      border: none;
      background-color: @greenDarkColor;
    }

    &[disabled]{
      background-color: @gayLightColor;
      box-shadow: none;
    }
  }

}

.inputTitlePosition {
  transform: translateY(-20px);
  color: @greenColor;
  font-size: 14px;
}

.login-item-input{
    border: none;
    outline: transparent;
    background-color: transparent;
    color: @gayColor;
    border-bottom: 1px solid @gayLightColor;
    width: 340px;
    max-width: 70vw; 
    height: 40px;
    box-sizing: border-box;
    font-size: 16px;

    &+.login-item-title.animatedPosition{
      .inputTitlePosition;
    }

}

.login-item-input.titleAnimation{

    &:focus+.login-item-title{
      .inputTitlePosition;
    }

    &:focus~i:after{
      width: 100%;
      left: 0;
    }
}

.error-message {
  font-size: 14px;
  color: #e25443;
  text-align: center;
  min-height: 22px;
  line-height: 1.6;
  max-width: 70vw;
}