.questionnaire {
  position: relative;
  height: calc(100vh - 50px);
  font-family: 'FangYuanKaTongPOPZi';
  .background {
    background-color: white;
  }

  .background-img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;

    @media (min-width: 1280px) {
      width: 75%;
    }

    @media (min-width: 1800px) {
      width: 50%;
    }
  }

  .main-page {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .bgm-btn {
    z-index: 100;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;
    height: 30px;
    background-image: url(../assets/bgm-btn.svg);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .bgm-anim {
    animation: rorate-anim 1.5s linear 0s infinite forwards;
  }

  @keyframes rorate-anim {
    0% { transform: rotateZ(0deg); }
    50% { transform: rotateZ(180deg); }
    100% { transform: rotateZ(360deg); }
  }

  .pad-question {
    // position: absolute;
    width: 100%;
    text-align: center;
    .pad-bg {
      display: inline-block;
      position: relative;

      .heart-bg {
        display: inline-block;
        width: 258px;
        animation: heart-scale .5s cubic-bezier(.99,1.9,.56,.73) 0s 1 forwards;
      }

      .lightning {
        position: absolute;
        top: -14px;
        left: 37.5px;
        width: 198.5px;
      }

      .horn {
        position: absolute;
        left: -25px;
        top: 90px;
        width: 47px;
        height: 46px;
        transform: rotateZ(333deg);
        animation: horn-shake 1s ease-in-out 0s infinite;
      }

      .alarm-clock {
        position: absolute;
        top: 0;
        right: -25px;
        width: 47px;
        height: 47px;
      }

      .girl {
        position: absolute;
        left: 0;
        bottom: -25px;
        width: 99px;
        height: 95px;
      }

      .small-heart1 {
        position: absolute;
        left: 60px;
        top: -10px;
        width: 15px;
        height: 13px;
        animation: heart-scale 2s ease 0s infinite;
      }
      .small-heart2 {
        position: absolute;
        left: 105px;
        top: 0px;
        width: 20px;
        height: 17px;
        animation: heart-scale 2s ease .2s infinite;
      }
      .small-heart3 {
        position: absolute;
        left: 80px;
        top: 20px;
        width: 19px;
        height: 17px;
        animation: heart-scale 2s ease .3s infinite;
      }
      .text {
        position: absolute;
        top: 75px;
        left: 40px;
        width: 180px;
        color: white;
        font-size: 20px;
        transform: scale(0);
        line-height: 24px;
        opacity: 0;
        animation: question-fade 1s cubic-bezier(.99, 1.9, .56, .73) forwards;
        animation-delay: .3px;
      }
    }
  }

  @keyframes heart-scale {
    0% { transform: scale(0); opacity: 0; }
    100% { transform: scale(1); opacity: 1;}
  }

  @keyframes horn-shake {
    0% { transform: scale(1) rotateZ(333deg); }
    10% { transform: scale(1.2) rotateZ(333deg); }
    20% { transform: scale(1.2) rotateZ(328deg); }
    30% { transform: scale(1.2) rotateZ(333deg); }
    40% { transform: scale(1.2) rotateZ(338deg); }
    50% { transform: scale(1.2) rotateZ(333deg); }
    60% { transform: scale(1.2) rotateZ(328deg); }
    70% { transform: scale(1.2) rotateZ(333deg); }
    80% { transform: scale(1.2) rotateZ(338deg); }
    90% { transform: scale(1.2) rotateZ(333deg); }
    100% { transform: scale(1) rotateZ(333deg); }
  }

  @keyframes question-fade {
    0% { transform: scale(0); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
  }

  .pad-answer {
    display: flex;
    height: calc(100% - 260px);
    align-items: center;
    animation: move-transform .8s cubic-bezier(.99, 1.9, .56, .73) forwards;
    animation-delay: .3px;
    transform: translateX(375px);
    opacity: 0;

    &.row {
      flex-direction: row;
      justify-content: space-around;
    }

    &.column {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .question-index {
    position: absolute;
    text-align: center;
    bottom: 10px;
    left: 0;
    width: 100%;
    pointer-events: none;
  }

  .answer-option {
    position: relative;
    text-align: center;

    .option-img {
      display: flex;
      flex-direction: column;

      >img {
        height: 90px;
      }
      >span {
        margin-top: 10px;
      }
    }

    .option-text {
      display: block;
      padding: 10px 10px;
      white-space: wrap;
      text-align: left;
      font-size: 16px;
      margin: 10px 15px 0;
      border-radius: 10px;
      border: 1px solid rgb(26,26,26);
      color: #28292b;
      cursor: pointer;
      line-height: 22px;
    }
  }

  @keyframes move-transform {
    0% { transform: translateX(375px); opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
  }

  .pad-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .cover-content {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    .examination-title {
      text-align: center;
      font-size: 40px;
      margin-top: 20vh;
    }

    .sub-title {
      text-align: center;
      font-size: 20px;
      margin-top: 20px;
    }

    .description {
      text-align: center;
      margin-top: 50px;
      line-height: 30px;
      color: #fa0d2f;
      font-size: 18px;
    }

    .button-wrap {
      margin-top: 25px;
      text-align: center;
      z-index: 1;
      cursor: pointer;
    }
  }

  .pad-description {
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .section {
      margin-bottom: 25px;
      line-height: 28px;
      padding: 0 15px;
    }

    .title {
      // margin-top: 40px;
      margin-bottom: 25px;
      font-size: 30px;
    }

    .button-wrap {
      margin-top: 50px;
    }

    .pad-input-name {
      .desc {
        font-size: 18px;
        display: block;
        margin-bottom: 15px;
      }
      .input {
        font-size: 18px;
        display: inline-block;
        text-align: center;
      }
      .error {
        font-size: 13px;
        display: block;
        color: rgb(255, 84, 72);
      }
    }
  }

  .button {
    border: 0;
    outline: 0;
    background-color: rgb(255, 84, 72);
    border-radius: 9px;
    box-shadow: rgb(255, 84, 72);
    color: white;
    width: 200px;
    height: 48px;
    font-size: 20px;
    cursor: pointer;
  }

  .pad-result {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: #28292b;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    .result-wrapper {
      width: calc(100% - 30px);
      max-width: 500px;
      background-color: white;
      border: 1px solid #ccc;
      // box-shadow: 0 15px 20px -16px rgba(0, 0, 0, .1);
    }

    .pad-image {
      background-color: #E9E9E9;
    }

    .resut-image {
      width: 100%;
      margin-top: 0px;
      display: block;
    }

    .pad-score {
      margin-top: 10px;
      text-align: center;
      font-size: 24px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      .score {
        color: #fa0d2f;
        font-size: 30px;
      }
    }

    .pad-desc {
      text-align: left;
      margin-top: 20px;
      font-size: 20px;
      padding: 0 15px 10px;
    }

    .result-text {
      font-size: 20px;
    }

    .pad-logo {
      text-align: center;
    }

    .logo {
      height: 30px;
    }
    
    .buttons {
      text-align: center;
      margin-top: 25px;
    }

    .btn {
      background-color: rgb(242, 166, 83);;
      border: 0;
      outline: 0;
      color: white;
      border-radius: 6px;
      width: 115px;
      height: 48px;
      margin: 0 20px;
      font-size: 16px;
    }

    .pad-download {
      text-align: center;
      margin-top: 15px;
      .btn {
        width: 150px;
      }
      .txt {
        margin-top: 10px;
      }
    }
  }

  @media (min-width: 588) {
    .pad-result {
      margin: 20%;
    }
  }

  .pad-share-mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
  }

  .pad-share {
    background-color: rgba(255, 255, 255, .6);
    position: absolute;
    height: 200px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: -apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",
    Helvetica,Arial,"PingFang TC","Microsoft Yahei","Hiragino Sans GB",
    "Heiti TC","WenQuanYi Micro Hei",sans-serif;

    .pad-loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .loading {
      animation: rorate-anim 1.5s linear 0s infinite forwards;
      width: 20px;
      margin-right: 5px;
    }

    .close {
      position: absolute;
      right: 0;
      top: 0;
      > img {
        width: 44px;
        height: 44px;
      }
    }

    .share-text {
      text-align: center;
      margin-top: 15px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    }

    .share-buttons {
      margin-top: 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .share-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
      img {
        width: 40px;
        height: 40px;
      }
      .text {
        text-align: center;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
        margin-top: 10px;
      }
    }
  }
}

@font-face {
  font-family: 'FangYuanKaTongPOPZi';
  src: url('../assets/font/FangYuanKaTongPOPZi.ttf') format("truetype");
}