.questionnaire {
  position: relative;
  height: calc(100vh - 50px);
  font-family: 'FangYuanKaTongPOPZi';
}
.questionnaire .background {
  background-color: white;
}
.questionnaire .background-img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
}
@media (min-width: 1280px) {
  .questionnaire .background-img {
    width: 75%;
  }
}
@media (min-width: 1800px) {
  .questionnaire .background-img {
    width: 50%;
  }
}
.questionnaire .main-page {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.questionnaire .bgm-btn {
  z-index: 100;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  background-image: url(../assets/bgm-btn.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.questionnaire .bgm-anim {
  animation: rorate-anim 1.5s linear 0s infinite forwards;
}
@keyframes rorate-anim {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.questionnaire .pad-question {
  width: 100%;
  text-align: center;
}
.questionnaire .pad-question .pad-bg {
  display: inline-block;
  position: relative;
}
.questionnaire .pad-question .pad-bg .heart-bg {
  display: inline-block;
  width: 258px;
  animation: heart-scale 0.5s cubic-bezier(0.99, 1.9, 0.56, 0.73) 0s 1 forwards;
}
.questionnaire .pad-question .pad-bg .lightning {
  position: absolute;
  top: -14px;
  left: 37.5px;
  width: 198.5px;
}
.questionnaire .pad-question .pad-bg .horn {
  position: absolute;
  left: -25px;
  top: 90px;
  width: 47px;
  height: 46px;
  transform: rotateZ(333deg);
  animation: horn-shake 1s ease-in-out 0s infinite;
}
.questionnaire .pad-question .pad-bg .alarm-clock {
  position: absolute;
  top: 0;
  right: -25px;
  width: 47px;
  height: 47px;
}
.questionnaire .pad-question .pad-bg .girl {
  position: absolute;
  left: 0;
  bottom: -25px;
  width: 99px;
  height: 95px;
}
.questionnaire .pad-question .pad-bg .small-heart1 {
  position: absolute;
  left: 60px;
  top: -10px;
  width: 15px;
  height: 13px;
  animation: heart-scale 2s ease 0s infinite;
}
.questionnaire .pad-question .pad-bg .small-heart2 {
  position: absolute;
  left: 105px;
  top: 0px;
  width: 20px;
  height: 17px;
  animation: heart-scale 2s ease 0.2s infinite;
}
.questionnaire .pad-question .pad-bg .small-heart3 {
  position: absolute;
  left: 80px;
  top: 20px;
  width: 19px;
  height: 17px;
  animation: heart-scale 2s ease 0.3s infinite;
}
.questionnaire .pad-question .pad-bg .text {
  position: absolute;
  top: 75px;
  left: 40px;
  width: 180px;
  color: white;
  font-size: 20px;
  transform: scale(0);
  line-height: 24px;
  opacity: 0;
  animation: question-fade 1s cubic-bezier(0.99, 1.9, 0.56, 0.73) forwards;
  animation-delay: 0.3px;
}
@keyframes heart-scale {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes horn-shake {
  0% {
    transform: scale(1) rotateZ(333deg);
  }
  10% {
    transform: scale(1.2) rotateZ(333deg);
  }
  20% {
    transform: scale(1.2) rotateZ(328deg);
  }
  30% {
    transform: scale(1.2) rotateZ(333deg);
  }
  40% {
    transform: scale(1.2) rotateZ(338deg);
  }
  50% {
    transform: scale(1.2) rotateZ(333deg);
  }
  60% {
    transform: scale(1.2) rotateZ(328deg);
  }
  70% {
    transform: scale(1.2) rotateZ(333deg);
  }
  80% {
    transform: scale(1.2) rotateZ(338deg);
  }
  90% {
    transform: scale(1.2) rotateZ(333deg);
  }
  100% {
    transform: scale(1) rotateZ(333deg);
  }
}
@keyframes question-fade {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.questionnaire .pad-answer {
  display: flex;
  height: calc(100% - 260px);
  align-items: center;
  animation: move-transform 0.8s cubic-bezier(0.99, 1.9, 0.56, 0.73) forwards;
  animation-delay: 0.3px;
  transform: translateX(375px);
  opacity: 0;
}
.questionnaire .pad-answer.row {
  flex-direction: row;
  justify-content: space-around;
}
.questionnaire .pad-answer.column {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.questionnaire .question-index {
  position: absolute;
  text-align: center;
  bottom: 10px;
  left: 0;
  width: 100%;
  pointer-events: none;
}
.questionnaire .answer-option {
  position: relative;
  text-align: center;
}
.questionnaire .answer-option .option-img {
  display: flex;
  flex-direction: column;
}
.questionnaire .answer-option .option-img > img {
  height: 90px;
}
.questionnaire .answer-option .option-img > span {
  margin-top: 10px;
}
.questionnaire .answer-option .option-text {
  display: block;
  padding: 10px 10px;
  white-space: wrap;
  text-align: left;
  font-size: 16px;
  margin: 10px 15px 0;
  border-radius: 10px;
  border: 1px solid #1a1a1a;
  color: #28292b;
  cursor: pointer;
  line-height: 22px;
}
@keyframes move-transform {
  0% {
    transform: translateX(375px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.questionnaire .pad-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.questionnaire .pad-cover .cover-content {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.questionnaire .pad-cover .examination-title {
  text-align: center;
  font-size: 40px;
  margin-top: 20vh;
}
.questionnaire .pad-cover .sub-title {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}
.questionnaire .pad-cover .description {
  text-align: center;
  margin-top: 50px;
  line-height: 30px;
  color: #fa0d2f;
  font-size: 18px;
}
.questionnaire .pad-cover .button-wrap {
  margin-top: 25px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}
.questionnaire .pad-description {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.questionnaire .pad-description .section {
  margin-bottom: 25px;
  line-height: 28px;
  padding: 0 15px;
}
.questionnaire .pad-description .title {
  margin-bottom: 25px;
  font-size: 30px;
}
.questionnaire .pad-description .button-wrap {
  margin-top: 50px;
}
.questionnaire .pad-description .pad-input-name .desc {
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
}
.questionnaire .pad-description .pad-input-name .input {
  font-size: 18px;
  display: inline-block;
  text-align: center;
}
.questionnaire .pad-description .pad-input-name .error {
  font-size: 13px;
  display: block;
  color: #ff5448;
}
.questionnaire .button {
  border: 0;
  outline: 0;
  background-color: #ff5448;
  border-radius: 9px;
  box-shadow: #ff5448;
  color: white;
  width: 200px;
  height: 48px;
  font-size: 20px;
  cursor: pointer;
}
.questionnaire .pad-result {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #28292b;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.questionnaire .pad-result .result-wrapper {
  width: calc(100% - 30px);
  max-width: 500px;
  background-color: white;
  border: 1px solid #ccc;
}
.questionnaire .pad-result .pad-image {
  background-color: #E9E9E9;
}
.questionnaire .pad-result .resut-image {
  width: 100%;
  margin-top: 0px;
  display: block;
}
.questionnaire .pad-result .pad-score {
  margin-top: 10px;
  text-align: center;
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.questionnaire .pad-result .pad-score .score {
  color: #fa0d2f;
  font-size: 30px;
}
.questionnaire .pad-result .pad-desc {
  text-align: left;
  margin-top: 20px;
  font-size: 20px;
  padding: 0 15px 10px;
}
.questionnaire .pad-result .result-text {
  font-size: 20px;
}
.questionnaire .pad-result .pad-logo {
  text-align: center;
}
.questionnaire .pad-result .logo {
  height: 30px;
}
.questionnaire .pad-result .buttons {
  text-align: center;
  margin-top: 25px;
}
.questionnaire .pad-result .btn {
  background-color: #f2a653;
  border: 0;
  outline: 0;
  color: white;
  border-radius: 6px;
  width: 115px;
  height: 48px;
  margin: 0 20px;
  font-size: 16px;
}
.questionnaire .pad-result .pad-download {
  text-align: center;
  margin-top: 15px;
}
.questionnaire .pad-result .pad-download .btn {
  width: 150px;
}
.questionnaire .pad-result .pad-download .txt {
  margin-top: 10px;
}
@media (min-width: 588) {
  .questionnaire .pad-result {
    margin: 20%;
  }
}
.questionnaire .pad-share-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.questionnaire .pad-share {
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  height: 200px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Arial, "PingFang TC", "Microsoft Yahei", "Hiragino Sans GB", "Heiti TC", "WenQuanYi Micro Hei", sans-serif;
}
.questionnaire .pad-share .pad-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.questionnaire .pad-share .loading {
  animation: rorate-anim 1.5s linear 0s infinite forwards;
  width: 20px;
  margin-right: 5px;
}
.questionnaire .pad-share .close {
  position: absolute;
  right: 0;
  top: 0;
}
.questionnaire .pad-share .close > img {
  width: 44px;
  height: 44px;
}
.questionnaire .pad-share .share-text {
  text-align: center;
  margin-top: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.questionnaire .pad-share .share-buttons {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.questionnaire .pad-share .share-buttons .share-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.questionnaire .pad-share .share-buttons img {
  width: 40px;
  height: 40px;
}
.questionnaire .pad-share .share-buttons .text {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin-top: 10px;
}
@font-face {
  font-family: 'FangYuanKaTongPOPZi';
  src: url('../assets/font/FangYuanKaTongPOPZi.ttf') format("truetype");
}
