.nav-container {
  display: flex;
  height: 50px;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, .9);
  border-bottom: 1px solid #EBEBEB;
  padding-left: 15px;
  .logo {
    margin: 0 10px 0;
    height: 30px;
    img {
      height: 30px;
    }
  }

  .pad-links {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .tab {
    // line-height: 50px;
    text-decoration: none;
    margin: 0 5px;
    color: #28292b;
    border-bottom: 2px solid transparent;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      color: #f5aa38;
      border-bottom: 2px solid #f5aa38;
    }
    &.selected {
      color: #f5aa38;
      border-bottom: 2px solid #f5aa38;
    }
  }
  .right {
    display: flex;
    padding: 0 15px;

    .name {
      flex: 1;
    }

    @media (max-width: 414px) {
      .name {
        display: none;
      }
    }
  }

  .signout {
    padding: 0 15px;
    display: block;
    cursor: pointer;
  }
}
